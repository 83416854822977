import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// sendmail
export const sendCurrentChatMail = createAsyncThunk(
  "sendCurrentChatMail/post",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      var bodyData = {
        account_id: accountID,
        conversation_id: payload.conversation_id,
        email: payload.email,
        timeZone: payload.timeZone,
      };

      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.post(
        `${basePathConfig.baseURL}/mailtranscripts`,
        bodyData,
        config
      );
      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
var initialState = {
  data: [],
  status: STATUSES.SUCCESS,
};
export const sendMailPreviousChatSlice = createSlice({
  name: "sendMail_PreviousChat",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCurrentChatMail.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(sendCurrentChatMail.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(sendCurrentChatMail.rejected, (state, action) => {
        state.data = [];
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setData, setStatus, reset } = sendMailPreviousChatSlice.actions;
export default sendMailPreviousChatSlice.reducer;
