import React from "react";
//import styled from "@emotion/styled";
import {
  Button,
  Card,
  //CardMedia as MuiCardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
//import ReactPlayer from "react-player";
//import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
//import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Player } from "video-react";
import AudioPlayer from "react-h5-audio-player";
import ImageZoom from "./ImageZoom";
//import html2canvas from "html2canvas";
const ChatMediaDialog = ({ openAdd, handleClickClose }) => {
  const [scale, setScale] = React.useState(1);
  const handleClose = () => {
    setScale(1);
    handleClickClose();
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.2) {
      setScale(scale - 0.1);
    }
  };

  //const cardMediaRef = React.useRef(null);
  //   const [open, setOpen] = React.useState(false);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  // const CardMedia = styled(MuiCardMedia)`
  //   weight: 100%;
  //   height: 70vh;
  //   cursor: pointer;
  // `;

  // const fullscreenMedia = () => {
  //   window.open(openAdd.MediaUrl, "_blank");
  // };

  // const downloadMedia = () => {
  //   // Create a temporary anchor element
  //   const downloadLink = document.createElement("a");

  //   // Set the anchor's href attribute to the image source
  //   downloadLink.href = openAdd.MediaUrl;

  //   // Set the download attribute to suggest a filename for the downloaded image
  //   downloadLink.download = "downloaded_image.jpg";
  //   // const fileName = "downloaded_image";
  //   // downloadLink.download = `${fileName}.${getFileExtension(openAdd.MediaUrl)}`;

  //   // Simulate a click on the anchor element to trigger the download
  //   downloadLink.click();
  // };

  // const handleSaveImage = () => {
  //   // Capture the image element directly
  //   const imageElement = document.getElementById("image-element");
  //   if (imageElement) {
  //     html2canvas(imageElement).then((canvas) => {
  //       console.log("handleSaveImage canvas == ", canvas);
  //       // Create a data URL for the image
  //       const dataURL = canvas.toDataURL("image/jpg");

  //       // Open a new window to save the image
  //       const newWindow = window.open();
  //       newWindow.document.write('<img src="' + dataURL + '" alt="Image" />');
  //     });
  //   }
  // };
  // const handleSaveImage = () => {
  //   console.log("imageRef.current == ", cardMediaRef.current);
  //   if (cardMediaRef.current) {
  //     html2canvas(cardMediaRef.current).then((canvas) => {
  //       // Create a data URL for the image
  //       const dataURL = canvas.toDataURL("image/jpeg");
  //       console.log("dataURL == ", dataURL);
  //       // Open a new window to save the image
  //       const newWindow = window.open();
  //       newWindow.document.write('<img src="' + dataURL + '" alt="Image" />');
  //     });
  //   }
  // };

  // /////// sample
  // function downloadImage(imageUrl) {
  //   try {
  //     // Create an image element
  //     const img = document.createElement("img");

  //     // Set the source of the image to the URL
  //     img.src = imageUrl;

  //     console.log("downloadImage img == ", img);

  //     // Add an event listener to wait for the image to load
  //     img.onload = function () {
  //       // Create a canvas element to draw the image
  //       const canvas = document.createElement("canvas");
  //       const context = canvas.getContext("2d");

  //       console.log("downloadImage canvas == ", canvas);
  //       console.log("downloadImage context == ", context);

  //       // Set the canvas dimensions to match the image
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       // Draw the image onto the canvas
  //       context.drawImage(img, 0, 0);

  //       // Convert the canvas content to a Blob
  //       canvas.toBlob(function (blob) {
  //         if (blob) {
  //           // Create a filename based on the current date
  //           const currentDate = new Date();
  //           const fileName = `image_${currentDate.getTime()}.jpeg`;

  //           // Create a URL for the Blob
  //           const blobUrl = window.URL.createObjectURL(blob);

  //           // Create an anchor element for downloading
  //           const a = document.createElement("a");
  //           a.href = blobUrl;
  //           a.download = fileName;

  //           // Simulate a click on the anchor element to trigger the download
  //           a.click();

  //           // Clean up by revoking the Blob URL and removing the anchor element
  //           window.URL.revokeObjectURL(blobUrl);
  //           document.body.removeChild(a);
  //         } else {
  //           console.error("Failed to convert canvas content to Blob.");
  //         }
  //       }, "image/jpeg");
  //     };

  //     // Append the image element to the document (this triggers loading)
  //     document.body.appendChild(img);
  //   } catch (error) {
  //     console.error("Error downloading image:", error);
  //   }
  // }

  // ///// sample React
  // const handleDownloadClick = async (imageUrl) => {
  //   // try {
  //   //   const response = await fetch(imageUrl);
  //   //   const blob = await response.blob();
  //   //   const currentDate = new Date();
  //   //   const fileName = `image_${currentDate.getTime()}.jpg`;

  //   //   // Create a URL for the Blob
  //   //   const blobUrl = window.URL.createObjectURL(blob);

  //   //   // Create an anchor element for downloading
  //   //   const a = document.createElement("a");
  //   //   a.href = blobUrl;
  //   //   a.download = fileName;

  //   //   // Simulate a click on the anchor element to trigger the download
  //   //   a.click();

  //   //   // Clean up by revoking the Blob URL and removing the anchor element
  //   //   window.URL.revokeObjectURL(blobUrl);
  //   //   document.body.removeChild(a);
  //   // } catch (error) {
  //   //   console.error("Error downloading image:", error);
  //   // }

  //   try {
  //     // Create a Blob from the image URL
  //     const blob = new Blob([imageUrl]);
  //     const currentDate = new Date();
  //     const fileName = `image_${currentDate.getTime()}.jpeg`;

  //     // Create a URL for the Blob
  //     const blobUrl = window.URL.createObjectURL(blob);

  //     // Create an anchor element for downloading
  //     const a = document.createElement("a");
  //     a.href = blobUrl;
  //     a.download = fileName;

  //     // Simulate a click on the anchor element to trigger the download
  //     a.click();

  //     // Clean up by revoking the Blob URL and removing the anchor element
  //     window.URL.revokeObjectURL(blobUrl);
  //     document.body.removeChild(a);
  //   } catch (error) {
  //     console.error("Error downloading image:", error);
  //   }
  // };

  // const getFileExtension = (url) => {
  //   const extension = url.split(".").pop();
  //   //console.log("extension == ", extension);
  //   return extension;
  // };
  return (
    <>
      <Dialog
        open={openAdd.Open}
        //onClose={handleClickClose}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Card>
              {openAdd.MediaType === "image" && (
                // <CardMedia
                //   image={openAdd.MediaUrl}
                //   title="Contemplative Reptile"
                //   id="image-element"
                //   ref={cardMediaRef}
                // />
                // eslint-disable-next-line jsx-a11y/alt-text
                // <img
                //   src={openAdd.MediaUrl}
                //   style={{ width: "100%", objectFit: "contain" }}
                // />
                <ImageZoom src={openAdd.MediaUrl} zoom={scale}></ImageZoom>
              )}
              {openAdd.MediaType === "video" && (
                <Player
                  playsInline
                  //poster="static/img/samples/videoPoster.jpg"
                  src={openAdd.MediaUrl}
                  autoPlay
                />
              )}
              {openAdd.MediaType === "audio" && (
                <AudioPlayer
                  autoPlay={true}
                  src={openAdd.MediaUrl}
                  onPlay={(e) => console.log("onPlay")}
                />
              )}
            </Card>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {openAdd.MediaType === "image" && (
            <>
              <Button onClick={handleZoomIn} color="primary">
                <Tooltip title="Zoom-In" placement="left">
                  <AddIcon />
                </Tooltip>
              </Button>
              <Button onClick={handleZoomOut} color="primary">
                <Tooltip title="Zoom-Out" placement="left">
                  <RemoveIcon />
                </Tooltip>
              </Button>
            </>
          )}

          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatMediaDialog;
