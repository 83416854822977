import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Typography,
  List,
  ListItemIcon,
  ListItem as ListItemListData,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentStatus } from "../../../../../redux/slices/conversationalActions";
import CircleIcon from "@mui/icons-material/Circle";
import { fun_LoginLogout } from "../../../../../redux/slices/socketsSlice";
const ListItemText = styled(MuiListItemText)(spacing);
const ListItemList = styled(ListItemListData)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
`;
const CircleIconOnline = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.online.background};
`;
const CircleIconOffline = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.offline.background};
`;
const CircleIconPause = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.pause.background};
`;

const CircleIconBuzy = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.buzy.background};
`;

const AgentStatus = () => {
  const { agentStatus } = useSelector((state) => state.conversationalAction);
  const { loginLogoutData } = useSelector((state) => state.socketsSlice);
  var [loginStatus, setLoginStatus] = React.useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAllAgentStatus());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
  }, [dispatch]);

  React.useEffect(() => {
    !!agentStatus && setLoginStatus(agentStatus);
  }, [agentStatus]);

  React.useEffect(() => {
    !!loginLogoutData && setLoginStatus(loginLogoutData);
  }, [loginLogoutData]);

  return (
    <>
      <List>
        {!!loginStatus && !!loginStatus.data ? (
          loginStatus.data.map((item, i) => (
            <ListItemList key={i}>
              <Avatar
                alt={item.displayname}
                src={item.avatar}
                style={{ marginRight: "5px" }}
              ></Avatar>
              {!!item.signinsignout[0] ? (
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 1 && (
                    <ListItemText
                      primary={item.displayname}
                      secondary="Online"
                    />
                  )) ||
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 2 && (
                    <ListItemText primary={item.displayname} secondary="Busy" />
                  )) ||
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 3 && (
                    <ListItemText
                      primary={item.displayname}
                      secondary="Pause"
                    />
                  )) ||
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 0 && (
                    <ListItemText
                      primary={item.displayname}
                      secondary="Offline"
                    />
                  )) ||
                (!item.signinsignout[0].availability[0] && (
                  <ListItemText
                    primary={item.displayname}
                    secondary="Not Available"
                  />
                ))
              ) : (
                <ListItemText
                  primary={item.displayname}
                  secondary="Not Available"
                />
              )}

              {!!item.signinsignout[0] ? (
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 1 && (
                    <ListItemIcon>
                      <CircleIconOnline />
                    </ListItemIcon>
                  )) ||
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 2 && (
                    <ListItemIcon>
                      <CircleIconBuzy />
                    </ListItemIcon>
                  )) ||
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 3 && (
                    <ListItemIcon>
                      <CircleIconPause />
                    </ListItemIcon>
                  )) ||
                (!!item.signinsignout[0].availability[0] &&
                  item.signinsignout[0].availability[0].isAvailable === 0 && (
                    <ListItemIcon>
                      <CircleIconOffline />
                    </ListItemIcon>
                  )) ||
                (!item.signinsignout[0].availability[0] && (
                  <ListItemIcon>
                    <CircleIcon style={{ color: "#D8363A" }} />
                  </ListItemIcon>
                ))
              ) : (
                <ListItemIcon>
                  <CircleIcon style={{ color: "#D8363A" }} />
                </ListItemIcon>
              )}
            </ListItemList>
          ))
        ) : (
          <Typography>
            There are no Previous Conversations associated to this Contacts.
          </Typography>
        )}
      </List>
    </>
  );
};

export default AgentStatus;
