import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
//import dashboardItems from "../components/sidebar/dashboardItems";
import dashboardItemsAdmin from "../components/sidebar/dashboardItemsAdmin";
import dashboardItemsAgent from "../components/sidebar/dashboardItemsAgent";

import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleDomainData } from "../redux/slices/whitelistsolutionSlice";

const drawerWidth = 90;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("atur");
  //console.log("Dashboard role == ", role);

  const domainName = window.location.hostname;
  // console.log("domainName  == ", domainName);

  const domainFetchSingleDataViaDomain = useSelector(
    (state) => state.whitelistsolution.domainFetchSingleDataViaDomain
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const [user, setUser] = React.useState([]);

  React.useEffect(() => {
    const items = localStorage.getItem("user");
    const atua = localStorage.getItem("atua");
    if (atua) {
      setUser(items);
    } else {
      navigate("/");
    }
  }, [navigate]);

  React.useEffect(() => {
    dispatch(fetchSingleDomainData(domainName));
  }, [dispatch, domainName]);

  React.useEffect(() => {
    localStorage.setItem(
      "awlid",
      domainFetchSingleDataViaDomain?.data?._id ?? "63e4c899170653603c44be33"
    );
  }, [domainFetchSingleDataViaDomain]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {/* <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
            user={!!user ? user : []}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
            user={!!user ? user : []}
          />
        </Box> */}
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          {!!role && role === "638f1029a1c0997b2085c2a2" ? (
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItemsAdmin}
              user={!!user ? user : []}
              domainFetch={domainFetchSingleDataViaDomain}
            />
          ) : (
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItemsAgent}
              user={!!user ? user : []}
              domainFetch={domainFetchSingleDataViaDomain}
            />
          )}
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {!!role && role === "638f1029a1c0997b2085c2a2" ? (
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={dashboardItemsAdmin}
              user={!!user ? user : []}
              domainFetch={domainFetchSingleDataViaDomain}
            />
          ) : (
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={dashboardItemsAgent}
              user={!!user ? user : []}
              domainFetch={domainFetchSingleDataViaDomain}
            />
          )}
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 1 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer domainFetch={domainFetchSingleDataViaDomain} />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
