import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

export const fetchAllDomainData = createAsyncThunk(
  "fetchAllDomainData/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/sub_admin/whitelist_solution`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// Find Whitelist_Solution by domain
export const fetchSingleDomainData = createAsyncThunk(
  "fetchSingleDomainData/fetch",
  async (domainName) => {
    try {
      const payload = {
        domain: domainName,
      };

      const res = await axios.post(
        `${basePathConfig.baseURL}/sub_admin/whitelist_solution/fetchbydomain`,
        payload
      );
      return await res.data.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// Find Whitelist_Solution by _id
export const fetchSingleDomainDataVia_id = createAsyncThunk(
  "fetchSingleDomainDataVia_id/fetch",
  async (id) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/sub_admin/whitelist_solution/fetchbydomain/${id}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// Create Whitelist_Solution
export const createDomainData = createAsyncThunk(
  "createDomainData/fetch",
  async (payloadData) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/sub_admin/whitelist_solution`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

const initialState = {
  domainFetchSingleDataViaDomain: {
    data: {},
    status: "",
    error: {},
  },
  domainFetchSingleDataViaId: {
    data: {},
    status: "",
    error: {},
  },
  domainFetchAllData: {
    data: {},
    status: "",
    error: {},
  },
  domainAddData: {
    data: {},
    status: "",
    error: {},
  },
  domainDeleteData: {
    data: {},
    status: "",
    error: {},
  },
  domainEditData: {
    data: {},
    status: "",
    error: {},
  },
};

export const whitelistsolutionSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDomainData.pending, (state) => {
        // fetch All Domains
        state.domainFetchAllData = {
          status: "loading",
          data: {},
          error: {},
        };
      })
      .addCase(fetchAllDomainData.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.domainFetchAllData = {
            status: "failed",
            data: {},
            error: action.payload.error,
          };
        } else {
          state.domainFetchAllData = {
            status: "succeeded",
            data: action.payload,
            error: {},
          };
        }
      })
      .addCase(fetchAllDomainData.rejected, (state, action) => {
        state.domainFetchAllData = {
          status: "failed",
          data: {},
          error: action.payload,
        };
      })
      .addCase(fetchSingleDomainData.pending, (state) => {
        // fetch Single Domains via domain
        state.domainFetchSingleDataViaDomain = {
          status: "loading",
          data: {},
          error: {},
        };
      })
      .addCase(fetchSingleDomainData.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.domainFetchSingleDataViaDomain = {
            status: "failed",
            data: {},
            error: action.payload.error,
          };
        } else {
          state.domainFetchSingleDataViaDomain = {
            status: "succeeded",
            data: action.payload,
            error: {},
          };
        }
      })
      .addCase(fetchSingleDomainData.rejected, (state, action) => {
        state.domainFetchSingleDataViaDomain = {
          status: "failed",
          data: {},
          error: action.payload,
        };
      })
      .addCase(fetchSingleDomainDataVia_id.pending, (state) => {
        // fetch Single Domains via id
        state.domainFetchSingleDataViaId = {
          status: "loading",
          data: {},
          error: {},
        };
      })
      .addCase(fetchSingleDomainDataVia_id.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.domainFetchSingleDataViaId = {
            status: "failed",
            data: {},
            error: action.payload.error,
          };
        } else {
          state.domainFetchSingleDataViaId = {
            status: "succeeded",
            data: action.payload,
            error: {},
          };
        }
      })
      .addCase(fetchSingleDomainDataVia_id.rejected, (state, action) => {
        state.domainFetchSingleDataViaId = {
          status: "failed",
          data: {},
          error: action.payload,
        };
      })
      .addCase(createDomainData.pending, (state) => {
        // add Domains
        state.domainAddData = {
          status: "loading",
          data: {},
          error: {},
        };
      })
      .addCase(createDomainData.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.domainAddData = {
            status: "failed",
            data: {},
            error: action.payload.error,
          };
        } else {
          state.domainAddData = {
            status: "succeeded",
            data: action.payload,
            error: {},
          };
        }
      })
      .addCase(createDomainData.rejected, (state, action) => {
        state.domainAddData = {
          status: "failed",
          data: {},
          error: action.payload,
        };
      });
  },
});

export default whitelistsolutionSlice.reducer;
