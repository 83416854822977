import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Button as MuiButton,
  Typography as MuiTypography,
  Divider as MuiDivider,
  InputLabel,
  Grid,
  FormControl as MuiFormControl,
  MenuItem,
  Select,
  CircularProgress as MuiCircularProgress,
  TextField,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchDaysList,
  fetchlabelsList,
} from "../../../../../redux/slices/reportOtherFunction";
import moment from "moment";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TabTables from "./TabTables";
import { fetchLabelsReport } from "../../../../../redux/slices/reportsLabels";
import HederLabelDetails from "./HederLabelDetails";
import { useNavigate } from "react-router-dom";
import { isValidToken } from "../../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const LabelStamp = styled.div`
  background-color: #fff;
  padding-top: 5px;
  padding-left: 5px;
`;

const ShowCalender = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [validation, setValidation] = React.useState(null);

  const chechValidation = () => {
    setValidation(null);
    !toDate && setValidation("Check To Date");
    !fromDate && setValidation("Check From Date");
    const startDate = moment(fromDate);
    const timeEnd = moment(toDate);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    diffDuration <= 0
      ? setValidation(
          "Please select From date that is at least 1 business days from To date."
        )
      : fetch_Data();
  };

  const fetch_Data = () => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      let PayloadData = {
        dayID: 6,
        start_date: moment(fromDate).format("YYYY-MM-DD"),
        end_date: moment(toDate).format("YYYY-MM-DD"),
      };
      dispatch(fetchLabelsReport(PayloadData));
    }
  };
  return (
    <>
      <FormControl m={2}>
        <TextField
          label="From Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
      </FormControl>

      <FormControl m={2}>
        <TextField
          label="To Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          ml={15}
        />
      </FormControl>
      <FormControl m={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={chechValidation}
          ml={5}
        >
          Search
        </Button>
      </FormControl>
      {!!validation && (
        <Wrapper style={{ color: "#F00000", marginLeft: "10px" }}>
          {validation}
        </Wrapper>
      )}
    </>
  );
};

const Labels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const [days, setDays] = React.useState("");
  const [labels, setlabels] = React.useState("");
  const [reportData, setReportData] = React.useState([]);
  const { dayList, status, labelsList } = useSelector(
    (state) => state.reportOtherFunction
  );
  var { labelReportdata, labelsStatus } = useSelector(
    (state) => state.reportLabels
  );

  React.useEffect(() => {
    dispatch(fetchDaysList());
    dispatch(fetchlabelsList());
    let PayloadData = {
      labelId: "",
      dayID: 0,
    };
    dispatch(fetchLabelsReport(PayloadData));
  }, [dispatch]);

  React.useEffect(() => {
    !!labelReportdata && setReportData(labelReportdata);
  }, [labelReportdata]);

  //  1st value in dropdown
  React.useEffect(() => {
    !!dayList && dayList.length > 0 && setDays(dayList[0].id);
    !!labelsList &&
      labelsList.length > 0 &&
      !!labelsList[0].labelsData &&
      !!labelsList[0].labelsData[0] &&
      setlabels(labelsList[0].labelsData[0]._id);
  }, [dayList, labelsList]);

  // filter Events
  const handleLabelsChange = (event) => {
    setlabels(event.target.value);
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      let PayloadData = {
        dayID: days,
        labelId: event.target.value,
      };
      dispatch(fetchLabelsReport(PayloadData));
    }
  };
  const handleDaysChange = (event) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      setDays(event.target.value);
      let PayloadData = {
        dayID: event.target.value,
        labelId: labels,
      };
      dispatch(fetchLabelsReport(PayloadData));
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <LocalOfferIcon /> Labels Overview Reports
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        {/* Filters */}
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={2}>
              <FormControl m={2}>
                <InputLabel id="demolabel">Labels</InputLabel>
                <Select
                  labelId="demolabel"
                  id="demo-simple-select"
                  value={labels}
                  label="Labels"
                  onChange={handleLabelsChange}
                >
                  {!!labelsList &&
                    labelsList.length > 0 &&
                    !!labelsList[0].labelsData &&
                    labelsList[0].labelsData.map((item, i) => (
                      <MenuItem value={item._id} key={i}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2}>
              <FormControl m={2}>
                <InputLabel id="demo-simple-select-label">Days</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={days}
                  label="Days"
                  onChange={handleDaysChange}
                >
                  {!!dayList &&
                    dayList.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={8}>
              {!!days && days === 6 && <ShowCalender />}
            </Grid>
          </Grid>
        </Wrapper>
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <LabelStamp>
                <HederLabelDetails
                  labeldetails={!!reportData ? reportData.labeldetails : ""}
                />
              </LabelStamp>
            </Grid>
          </Grid>
        </Wrapper>
        {/* Tabs */}
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={12}>
              {!!labelsStatus && labelsStatus === "loading" && (
                <CircularProgress m={2} />
              )}
              {!!status && status === "loading" && <CircularProgress m={2} />}
              <Divider my={2} sx={{ borderBottomWidth: 2 }} />
              <TabTables reportData={!!reportData ? reportData : []} />
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default Labels;
