import React from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  //Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  sendCurrentChatMail,
} from "../../../../../redux/slices/sendMailPreviousChat";
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const SendMail = ({ openAdd, handleAddClose, handleAddClickOpen }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.sendMail_PreviousChat);
  const smtp_id = localStorage.getItem("awlid") || "638f1029a1c0997b2085c2a2";

  const [sendEmail, setSendEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errors, setErrors] = React.useState("");

  const SendMail = async () => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    //console.log(timeZone);
    setIsLoading(true);
    try {
      var payload = {
        conversation_id: openAdd.conversation_id,
        email: sendEmail,
        timeZone: timeZone,
        smtp_id: smtp_id,
      };
      await dispatch(sendCurrentChatMail(payload));
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrors("Error ");
    }
    await timeOut(4000);
    await dispatch(reset());
    setSendEmail("");
  };
  return (
    <Dialog
      open={openAdd.Open}
      onClose={handleAddClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">
        Send conversation transcript
      </DialogTitle>
      <DialogContent>
        {isLoading === true && <CircularProgress m={2} />}
        {isError === true && (
          <Alert mb={4} variant="filled" severity="error">
            {!!errors && errors}
          </Alert>
        )}
        {!!data && !!data.status && data.status !== 200 && (
          <Alert variant="filled" severity="error">
            {!!data && !!data.message && data.message}
          </Alert>
        )}
        {!!data && data.status === 200 && (
          <Alert variant="filled" severity="success">
            "Your mail has been sent successfuly"
          </Alert>
        )}
        <DialogContentText>
          Send a copy of the conversation transcript to the specified email
          address.
        </DialogContentText>
        <DialogContentText mt={3}>
          Send the transcript to email address
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={sendEmail}
          onChange={(e) => setSendEmail(e.target.value)}
          fullWidth
          mt={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            SendMail(openAdd.conversation_id);
          }}
          color="primary"
        >
          Send email
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SendMail;
