import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Button as MuiButton,
  Typography as MuiTypography,
  Divider as MuiDivider,
  InputLabel,
  Grid,
  FormControl as MuiFormControl,
  MenuItem,
  Select,
  CircularProgress as MuiCircularProgress,
  TextField,
} from "@mui/material";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgentsList,
  fetchDaysList,
} from "../../../../../redux/slices/reportOtherFunction";
import moment from "moment";
import TabTables from "./TabTables";
import { fetchCsatReport } from "../../../../../redux/slices/reportCSAT";
import BarChart from "./BarChart";
import { isValidToken } from "../../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const ShowCalender = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [validation, setValidation] = React.useState(null);

  const chechValidation = () => {
    //alert("chechValidation");
    setValidation(null);
    !toDate && setValidation("Check To Date");
    !fromDate && setValidation("Check From Date");
    const startDate = moment(fromDate);
    const timeEnd = moment(toDate);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    diffDuration.days() <= 0
      ? setValidation(
          "Please select From date that is at least 1 business days from To date."
        )
      : fetch_Data();
  };

  const fetch_Data = () => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      //alert("fetch_Data");
      let PayloadData = {
        dayID: 6,
        start_date: moment(fromDate).format("YYYY-MM-DD"),
        end_date: moment(toDate).format("YYYY-MM-DD"),
      };
      dispatch(fetchCsatReport(PayloadData));
    }
  };
  return (
    <>
      <FormControl m={2}>
        <TextField
          label="From Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
      </FormControl>

      <FormControl m={2}>
        <TextField
          label="To Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          ml={15}
        />
      </FormControl>
      <FormControl m={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={chechValidation}
          ml={5}
        >
          Search
        </Button>
      </FormControl>
      {!!validation && (
        <Wrapper style={{ color: "#F00000", marginLeft: "10px" }}>
          {validation}
        </Wrapper>
      )}
    </>
  );
};
const CSATReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const { dayList, status } = useSelector((state) => state.reportOtherFunction);

  var { csatReportdata, csatStatus } = useSelector((state) => state.reportCSAT);
  // console.log("csatStatus == ", csatStatus);

  React.useEffect(() => {
    dispatch(fetchDaysList());
    dispatch(fetchAgentsList());
    let PayloadData = {
      agentID: "",
      dayID: 0,
    };
    dispatch(fetchCsatReport(PayloadData));
  }, [dispatch]);

  const [days, setDays] = React.useState("");

  //  1st value in dropdown
  React.useEffect(() => {
    !!dayList && dayList.length > 0 && setDays(dayList[0].id);
  }, [dayList]);

  // filter Events
  const handleDaysChange = (event) => {
    setDays(event.target.value);
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      let PayloadData = {
        dayID: event.target.value,
        start_date: "",
        end_date: "",
      };
      dispatch(fetchCsatReport(PayloadData));
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {/* Report Title */}
        <Wrapper>
          <TitleTypography variant="h4">
            <TrendingUpIcon /> CSAT Overview Reports
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        {/* Filters */}
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={2}>
              <FormControl m={2}>
                <InputLabel id="demo-simple-select-label">Days</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={days}
                  label="Days"
                  onChange={handleDaysChange}
                >
                  {!!dayList &&
                    dayList.map((item, i) => (
                      <MenuItem value={item.id} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={8}>
              {!!days && days === 6 && <ShowCalender />}
            </Grid>
            <Grid item xs={12} lg={2}></Grid>
          </Grid>
        </Wrapper>
        <Wrapper>
          {!!csatReportdata && !!csatReportdata.rating_Count && (
            <BarChart csatReportdata={csatReportdata} />
          )}
        </Wrapper>
        {/* Tabs */}
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={12}>
              {!!csatStatus && csatStatus === "loading" && (
                <CircularProgress m={2} />
              )}
              {!!status && status === "loading" && <CircularProgress m={2} />}
              <Divider my={2} sx={{ borderBottomWidth: 2 }} />
              <TabTables csatReportdata={csatReportdata} />
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default CSATReport;
