import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  ImageListItem,
  // Avatar,
  Paper,
  //Typography,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/1spoc-logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleDomainData } from "../../redux/slices/whitelistsolutionSlice";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 250px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const dispatch = useDispatch();
  const domainFetchSingleDataViaDomain = useSelector(
    (state) => state.whitelistsolution.domainFetchSingleDataViaDomain
  );

  const domainName = window.location.hostname;
  console.log("\n\n Dashboard domainName  == " + domainName);
  React.useEffect(() => {
    dispatch(fetchSingleDomainData(domainName));
  }, [dispatch, domainName]);
  return (
    <React.Fragment>
      {domainFetchSingleDataViaDomain?.data?.logo_url &&
        domainFetchSingleDataViaDomain?.data?.signIn_logo_url && (
          <ImageListItem>
            <img
              src={
                domainFetchSingleDataViaDomain?.data?.signIn_logo_url ??
                domainFetchSingleDataViaDomain?.data?.logo_url
              }
              alt="logo"
              loading="lazy"
              style={{
                maxWidth: "250px",
                // eslint-disable-next-line no-template-curly-in-string
                marginBottom: "32px",
              }}
            />
          </ImageListItem>
        )}
      <Wrapper>
        <Helmet title="Sign In" />
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
