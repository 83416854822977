import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import loginReducer from "./slices/login";
import settingReducer from "./slices/settings";
import labelsReducer from "./slices/labels";
import cannedResponsesReducer from "./slices/cannedResponses";
import agentsReducer from "./slices/agents";
import companyAttributesReducer from "./slices/companyAttributes";
import accountReducer from "./slices/account";
import contactReducer from "./slices/contact";
import socketsReducer from "./slices/socketsSlice";
import chatSettingsReducer from "./slices/chatSettings";
import conversationalActionReducer from "./slices/conversationalActions";
import passwordConfirmationReducer from "./slices/passwordConfirmation";
import csatRatingReducer from "./slices/csatRating";
import reserPasswordReducer from "./slices/resetPassword";
import sendMailPreviousChatReducer from "./slices/sendMailPreviousChat";
import otherMessagesReducer from "./slices/otherMessages";
import pauseUnpauseReducer from "./slices/pauseUnpause";
import profileDetailsReducer from "./slices/profileDetails";
import chatGTPReducer from "./slices/chatGTPslice";
import reportListReducer from "./slices/reportList";
import reportOtherFunctionReducer from "./slices/reportOtherFunction";
import reportAgentReducer from "./slices/reportAgentWise";
import reportCsatReducer from "./slices/reportCSAT";
import reportOverviewSlice from "./slices/reportsOverview";
import reportChannelSlice from "./slices/reportChannel";
import reportLoginLogoutSlice from "./slices/reportLoginLogout";
import reportLabelsSlice from "./slices/reportsLabels";
import agentOtherDataSlice from "./slices/agentOtherDataSlice";
import whitelistsolutionSlice from "./slices/whitelistsolutionSlice";
import botlogsSlice from "./slices/botLogsSlice";
export const store = configureStore(
  {
    reducer: {
      counter: counterReducer,
      login: loginReducer,
      settings: settingReducer,
      labels: labelsReducer,
      cannedResponses: cannedResponsesReducer,
      agents: agentsReducer,
      companyAttributes: companyAttributesReducer,
      account: accountReducer,
      contact: contactReducer,
      socketsSlice: socketsReducer,
      chatSettings: chatSettingsReducer,
      conversationalAction: conversationalActionReducer,
      password_Confirmation: passwordConfirmationReducer,
      csatRating: csatRatingReducer,
      reset_password: reserPasswordReducer,
      sendMail_PreviousChat: sendMailPreviousChatReducer,
      otherMessages: otherMessagesReducer,
      pauseUnpause: pauseUnpauseReducer,
      profileDetails: profileDetailsReducer,
      chatGTP: chatGTPReducer,
      reportList: reportListReducer,
      reportOtherFunction: reportOtherFunctionReducer,
      reportAgent: reportAgentReducer,
      reportCSAT: reportCsatReducer,
      reportOverview: reportOverviewSlice,
      reportChannel: reportChannelSlice,
      reportLoginLogout: reportLoginLogoutSlice,
      reportLabels: reportLabelsSlice,
      agentOtherData: agentOtherDataSlice,
      whitelistsolution: whitelistsolutionSlice,
      botlogs: botlogsSlice,
    },
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
