import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Button,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography as MuiTypography,
  TextField as MuiTextField,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnswer } from "../../../../redux/slices/chatGTPslice";
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);
const CardContentProfile = styled(CardContent)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
`;
const ChatGTP = ({
  openChatGTPAdd,
  handleClickOpenChatGTP,
  handleCloseChatGTP,
}) => {
  var [anyQuestion, setAnyQuestion] = React.useState("");
  var [GTPAnswer, setGTPAnswer] = React.useState("");
  var [GTPQuestion, setGTPQuestion] = React.useState("");
  const { chatGTPAnswer, status } = useSelector((state) => state.chatGTP);
  var dispatch = useDispatch();
  React.useEffect(() => {
    !!chatGTPAnswer && setGTPAnswer(chatGTPAnswer.data);
  }, [chatGTPAnswer]);

  React.useEffect(() => {
    !!GTPAnswer && setGTPQuestion(GTPAnswer.question);
  }, [GTPAnswer]);

  const handleQuestionClick = async () => {
    setGTPQuestion(anyQuestion);
    setGTPAnswer("");
    setAnyQuestion("");
    await dispatch(fetchAnswer(anyQuestion));
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={openChatGTPAdd.Open}
      onClose={handleCloseChatGTP}
    >
      {/* <DialogTitle>Help Pannel</DialogTitle> */}
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <CloseIcon onClick={handleCloseChatGTP} /> Help Pannel
          </Typography>
        </DialogContentText>
        <Box>
          <Typography variant="h6" gutterBottom>
            {!!GTPQuestion && `Question: ${GTPQuestion}`}
          </Typography>
          {!!status && status === "loading" && <CircularProgress m={2} />}
          <Card mb={6}>
            <CardContentProfile>
              {!!GTPAnswer && GTPAnswer.content}
            </CardContentProfile>
          </Card>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Type any question
              </Typography>
              <TextField
                id="question"
                label="Any Question"
                variant="outlined"
                //defaultValue={displayname}
                value={anyQuestion}
                onChange={(e) => setAnyQuestion(e.target.value)}
                fullWidth
                my={2}
              />
              <Button
                variant="contained"
                color="primary"
                mt={3}
                onClick={handleQuestionClick}
              >
                Answer
              </Button>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChatGTP;
