import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
export const fetchLogin = createAsyncThunk("login/fetch", async (payload) => {
  try {
    const res = await axios.post(`${basePathConfig.baseURL}/app/login`, {
      email: payload.email,
      password: payload.password,
    });
    var user = {
      uid: res.data.user._id,
      email: res.data.user.email,
      name: res.data.user.name,
      displayname: res.data.user.displayname,
      avatar: res.data.user.avatar,
    };

    var role = {
      role:
        res.data.user.role === 0
          ? "638f1029a1c0997b2085c2a2"
          : "638f1032a1c0997b2085c2a6",
    };
    //console.log("res.data == ", JSON.stringify(res.data));
    localStorage.setItem("accessToken", res.data.accessToken.token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("atur", role.role);
    localStorage.setItem("atua", res.data.user.account_id);
    return await res.data;
  } catch (error) {
    //const data = await error.response.data;
    var ErrorData = await errorHandler(error);
    return ErrorData;
  }
});

export const fetchLogOut = createAsyncThunk("logout/post", async () => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    const user = JSON.parse(localStorage.getItem("user"));
    const emailID = user.email;
    var payload = {
      email: emailID,
      account_id: accountID,
    };

    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.post(
        `${basePathConfig.baseURL}/app/logout`,
        payload,
        config
      );

      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

export const fetchLoginLogOut_Socket = createAsyncThunk(
  "loginlogout/socket",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      var payload = {
        account_id: accountID,
      };

      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/loginlogout`,
          payload,
          config
        );

        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

// initialize userToken from local storage

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    data: [],
    logoutData: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("accessToken"); // deletes token from storage
      localStorage.removeItem("atur"); // deletes token from storage
      localStorage.removeItem("atua"); // deletes token from storage
      localStorage.removeItem("user"); // deletes user from storage
      localStorage.removeItem("awlid"); // deletes Host from storage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchLogOut.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchLogOut.fulfilled, (state, action) => {
        state.logoutData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchLogOut.rejected, (state, action) => {
        state.logoutData = [];
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setLogin, setStatus, setLogoutData, logout } =
  loginSlice.actions;
export default loginSlice.reducer;
