import React from "react";
import styled from "@emotion/styled";
import {
  Alert,
  Avatar as MuiAvatar,
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Paper,
  DialogTitle,
  DialogContentText,
  Typography as MuiTypography,
  TextField as MuiTextField,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProfile,
  updateProfile,
  uploadProfile,
  reset,
} from "../../redux/slices/profileDetails";
import CloseIcon from "@mui/icons-material/Close";
import { fetchCurrentChat } from "../../redux/slices/chatSettings";

const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const CardContentProfile = styled(CardContent)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
`;
const CircularProgress = styled(MuiCircularProgress)(spacing);

function Public({ fileData, handleAddClose }) {
  var [agent, setAgent] = React.useState("");
  var [role, setRole] = React.useState("");

  var [file, setFile] = React.useState("");
  var [fileStatus, setFileStatus] = React.useState("notUploadedFile");
  var [fileSize, setFileSize] = React.useState(false);
  var [fileload, setFileload] = React.useState(false);
  var [fileloadError, setFileloadError] = React.useState("");
  const { conversationId } = useSelector((state) => state.chatSettings);

  var [name, setName] = React.useState("");
  var [displayname, setDisplayname] = React.useState("");
  const dispatch = useDispatch();
  const { uploadImage, uploadData } = useSelector(
    (state) => state.profileDetails
  );

  React.useEffect(() => {
    !!uploadImage ? setFile(uploadImage.data) : setFile("");
    !!uploadImage
      ? setFileStatus(uploadImage.message)
      : setFileStatus("notUploadedFile");
  }, [uploadImage]);

  React.useEffect(() => {
    !!agent && !!agent.name && setName(agent.name);
    !!agent && !!agent.displayname && setDisplayname(agent.displayname);
    !!agent && !!agent.avatar && setFile(agent.avatar);
  }, [agent]);

  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
    setRole(localStorage.getItem("atur"));
  }, []);

  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, [uploadData]);

  const changeHandler = async (event) => {
    // 5242855 as 5mb and 2002855 is 2mb
    // console.log("event.target.files[0].size == ", event.target.files[0].size);
    // console.log("event.target.files[0].type == ", event.target.files[0].type);
    var targetFiles = event.target.files[0].type;
    // console.log("targetFiles == ", targetFiles);
    var fileFormate = targetFiles.split("/");
    // console.log("fileFormate == ", fileFormate);
    setFileload(true);
    if (fileFormate[0] === "image") {
      if (parseInt(event.target.files[0].size) > 2002855) {
        setFileSize(true);
        setFileloadError(
          "The file is too large. The total size is more than 2MB!"
        );
        await timeOut(2000);
        setFileloadError("");
        setFileSize(false);
        setFileload(false);
      } else {
        setFileStatus("");
        await dispatch(uploadProfile(event.target.files[0]));
        setFileload(false);
      }
    } else {
      setFileloadError(
        "File format is not valid ,it should have a valid image file extension like .jpg, .png, or .gif."
      );
      setFileSize(true);
      await timeOut(2000);
      setFileloadError("");
      setFileSize(false);
      setFileload(false);
    }
    event.target.value = "";
  };

  const changeDeleteHandler = async (event) => {
    setFileStatus("");
    await dispatch(deleteProfile(file));
    setFile("");
  };

  const changeUpdateHandler = async (event) => {
    var payload = {
      name: name,
      displayname: displayname,
      email: agent.email,
      avatar: file,
      role: role === "638f1032a1c0997b2085c2a6" ? 1 : 0,
    };
    await dispatch(updateProfile(payload));
    !!conversationId && dispatch(fetchCurrentChat(conversationId));
    await timeOut(3000);
    await dispatch(reset());
  };
  // test staging
  return (
    <>
      <Card mb={6}>
        <CardContentProfile>
          {/* <Spacer mb={4} /> */}

          <Centered>
            <Avatar alt={agent.displayname} src={file} />
            <Typography variant="body2" component="div" gutterBottom>
              <Box fontWeight="fontWeightMedium">{agent.displayname}</Box>
              <Box fontWeight="fontWeightRegular">
                {role === "638f1032a1c0997b2085c2a6" ? "Agent" : "Admin"}
              </Box>
              <Box fontWeight="fontWeightRegular">{agent.email}</Box>
            </Typography>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="profile-button-file"
              //multiple
              type="file"
              onChange={changeHandler}
            />
            {!file && (
              <label htmlFor="profile-button-file">
                {!fileload && (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    mt={5}
                  >
                    <CloudUpload mr={2} /> Upload
                  </Button>
                )}
                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in size
                  and a file size of up to 2MB.
                </Typography>
                {fileSize && (
                  <Alert mb={4} severity="error">
                    {fileloadError}
                  </Alert>
                )}
              </label>
            )}
            {!!file && (
              <label>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  mt={5}
                  onClick={changeDeleteHandler}
                >
                  Remove
                </Button>
              </label>
            )}
          </Centered>
          {!fileStatus && <CircularProgress m={2} />}
        </CardContentProfile>
      </Card>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Public info
          </Typography>
          {!!uploadData &&
            !!uploadData.status &&
            parseInt(uploadData.status) !== 200 && (
              <Alert severity="error" my={3}>
                {!!uploadData && uploadData.message}
              </Alert>
            )}

          {!!uploadData &&
            !!uploadData.status &&
            parseInt(uploadData.status) === 200 && (
              <Alert severity="success" my={3}>
                Your data has been updated successfully!
              </Alert>
            )}
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            defaultValue={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            my={2}
          />
          <TextField
            id="displayName"
            label="Display Name"
            variant="outlined"
            defaultValue={displayname}
            value={displayname}
            onChange={(e) => setDisplayname(e.target.value)}
            fullWidth
            my={2}
          />
          <Button
            variant="contained"
            color="primary"
            mt={3}
            onClick={changeUpdateHandler}
          >
            Save changes
          </Button>
        </CardContent>
      </Card>
    </>
  );
}
// test react project
const Profile = ({ openAdd, handleAddClose, handleAddClickOpen }) => {
  return (
    <Card mb={12}>
      <CardContent>
        <Paper mt={1}>
          <Dialog
            open={openAdd.Open}
            onClose={handleAddClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="form-dialog-title">
              <DialogContentText>
                <Typography variant="h6" gutterBottom>
                  <CloseIcon
                    onClick={handleAddClose}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Profile Details
                </Typography>
              </DialogContentText>
            </DialogTitle>
            <DialogContent>
              <Public fileData={openAdd.fileData} />
            </DialogContent>
          </Dialog>
        </Paper>
      </CardContent>
    </Card>
  );
};
// test Comments 111
export default Profile;
